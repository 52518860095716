.header {
    position: fixed;
    /* Ensures it sticks to the top */
    top: 0;
    left: 0;
    width: 100%;
    background-color: whitesmoke;
    padding: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    transition: transform 0.5s ease;
    transform: translateY(-100%);
    z-index: 1000;
    flex-direction: row;
    align-items: center;
}

[data-theme='dark'] .header {
    background-color: #4f4f4f;
}

[data-theme='light'] .themeToggleButton {
    color : #2f2f2f;
}

[data-theme='dark'] .logo-text {
    color : whitesmoke;
}

[data-theme='dark'] .nav-links-ul a {
    color: whitesmoke;
}

.visible {
    transform: translateY(0);
    /* Moves it into view */
}

.nav-links-ul {
    display: flex;
    gap: 30px;
    justify-content: space-between;
}

ul {
    list-style-type: none;
}

.nav-links {
    display:flex;
    justify-content: center;
}

.nav-links nav {
    margin-right: 80px;
}

.nav-links-ul a {
    text-decoration: none;
    color: #2f2f2f;
    font-family: "Jersey 10", sans-serif;
    font-size: 1.2em;
}

.logo-text {
    font-family: "Jersey 10", sans-serif;
    color: #2f2f2f;
    margin-left: 80px;
    font-size: 2em;
}

@media (max-width: 767px) {
    .visible {
      display: none;
    }
  }