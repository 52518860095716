h1 {
    font-family: "Jersey 10", sans-serif;
    margin: 0;
    padding: 10px;
}


.experience-card {
    background-color: whitesmoke;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
}

.experience-card h2 {
    font-size: 2em;
    font-family: "Jersey 10", sans-serif;
}

[data-theme='dark'] .experience-card {
    color: whitesmoke;
    background-color: #2f2f2f;
}

[data-theme='dark'] .experience {
    background-color: #4f4f4f;
}


.experience h1 {
    font-size: 3em;
    font-family: "Jersey 10", sans-serif;
}

.experience-card h3 {
    font-family: "Jersey 10", sans-serif;
    font-size: 1.5em;
    display: inline;
}

.experience-card h4 {
    font-family: "Jersey 10", sans-serif;
    font-size: 1.3em;
}

.experience-card ul {
    list-style: none;
    text-align: left;
}

.organization-link {
    display: flex;
    flex-direction: row;
}



.experience-card li {
    font-family: monospace;
    display: flex;
    /* Use flexbox to align items */
    align-items: center;
    /* Center items vertically */
    margin-bottom: 10px;
    /* Space between list items */
    flex-direction: row;
}

.experience-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Two columns by default */

}

.skill-pill {
    display: inline-block;
    /* Ensures the pill only takes up as much width as its content */
    padding: 8px 16px;
    /* Adds space inside the pill */
    border-radius: 5px;
    /* Creates the pill shape with rounded corners */
    border: 2px solid #2f2f2f;
    background-color: whitesmoke;
    /* Set a background color for the pill */
    color: #2f2f2f;
    /* Set text color inside the pill */
    font-size: 14px;
    /* Adjust font size */
    text-align: center;
    /* Centers the text */
    margin: 5px;
    /* Adds some space around each pill */
    cursor: default;
    /* Makes it look like a non-interactive element */
}

@media (max-width: 767px) {
    .experience-cards {
        grid-template-columns: repeat(1, 1fr);
        /* Two columns by default */
    }

    .experience-card h2 {
        font-family: "New Amsterdam", sans-serif;
    }

    .experience h1 {
        font-family: "New Amsterdam", sans-serif;
    }

    .education h1 {
        font-family: "New Amsterdam", sans-serif;
    }

    .experience-card h3 {
        font-family: "New Amsterdam", sans-serif;
    }

    .education h3 {
        font-family: "New Amsterdam", sans-serif;
    }
}

@media (min-width: 768px) {
    .experience {
        padding: 20px;
    }
}

.careticon {
    margin-right: 10px;
}

[data-theme='dark'] .education {
    background-color: #4f4f4f;
}