.contactme {
    padding: 20px;
    padding-bottom: 100px;
}

.social-media-links-contactme {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

[data-theme='dark'] .contactme {
    background-color: #2f2f2f;
}