.landing-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #2f2f2f;
    position: relative;
}

.landing-text {
    font-size: 8rem;
    font-weight: bold;
    color: #f5f5f5;
    font-family: "Jersey 10", sans-serif;
    margin-top: 1vh;
    margin-bottom: 1vh;
}

.landing-sub-text {
    font-size: 2rem;
    color: whitesmoke;
    font-family: "Roboto Mono", monospace;
    margin-top: 0;
    height: 50px;
}

@media (max-width: 767px) {
    .landing-text {
        font-size: 6rem;
    }
}

@media (max-width: 767px) {
    .landing-sub-text {
        font-size: 1rem;
    }
}

.scroll-indicator {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 10px;
}

.scroll-arrow {
    color: whitesmoke;
    height: 5vh;
    animation: bounce 1.5s infinite;
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-5px);
    }
}

.instagram-icon {
    color: #c13484;
}

.youtube-icon {
    color: #ff0000
}

.github-icon {
    color: #ffffff
}

.linkedin-icon {
    color: #0077B5
}


.social-media-container {
    display: flex;
    gap: 20px;
    /* Space between the icons */
}

.social-media-link {
    text-decoration: none;
    color: inherit;
    transition: transform 0.3s;
}

.social-media-link:hover {
    transform: scale(1.2);
}

.leetcode-icon {
    color: #e7a41e;
}

.icon {
    height: 9vh;
}