.sidebar {
  background-color: whitesmoke;
  /* Adjust as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

[data-theme='dark'] .sidebar {
  background-color: #2f2f2f;
}

.profile-photo img {
  height: 30vh;
  border-radius: 10%;
}

.social-media-links {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px
}

.social-media-link {
  text-decoration: none;
  color: whitesmoke;
  font-size: 1.2rem;
}

.social-media-link {
  text-decoration: none;
  color: inherit;
  transition: transform 0.3s;
}

.social-media-link:hover {
  transform: scale(1.2);
}


[data-theme='dark'] .icon {
  height: 6vh;
  width: 6vh;
  color: whitesmoke;
}

[data-theme='dark'] h1 {
  color: whitesmoke
}

[data-theme='dark'] .icon {
  fill : whitesmoke;
}

[data-theme='dark'] .about-me-text {
  color : whitesmoke;
}

.icon {
  height: 6vh;
  width: 6vh;
  color: #2f2f2f
}


.profile-photo {
  overflow: hidden;
  /* Prevents image overflow */
  display: inline-block;
  /* Allows spacing around the container */
}

.profile-photo {
  transition: transform 0.3s ease;
  /* Smooth transition effect */
}

.profile-photo:hover {
  transform: scale(1.01);
  /* Scales up the image */
}

.sidebar h1 {
  font-family: "Jersey 10", sans-serif;
}

.leetcode-icon {
  margin-left: 0px;
  margin-right: 0px;
}

.about-me-text {
  font-family: "Jersey 10", sans-serif;
  font-size: 2em;
  color: #2f2f2f;
  text-align: left;
  overflow-wrap: break-word;
  /* or 'right', 'center', 'justify' */
}

@media (max-width: 767px) {
  .about-me-text {
      font-size: 1.5em;
  }
}

@media (min-width: 768px) {
  .sidebar {
      height: 100vh;
  }
}