.bottom-bar {
    background-color: #2f2f2f;
    padding: 0px;
    margin: 0px;
    position: fixed;
    bottom: 0;
    width: 100%;
    transition: transform 0.5s ease;
    transform: translateY(100%);
    height: 60px;
}

[data-theme='dark'] .bottom-bar {
    background-color: #4f4f4f;
}

.bottom-bar.visible {
    transform: translateY(0);
}

.bottom-bar-ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    padding-top: 10px;
}

@media (min-width: 768px) {
    .bottom-bar {
        display: none;
    }
}

@media (max-width: 767px) {
    .bottom-bar {
        display: block;
    }
}

.bottom-bar-item span {
    color: whitesmoke;
    font-size: 0.5em;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.bottom-bar-item-logo {
    height: 2vh;
}

.bottom-bar-item-icon {
    height: 2vh;
}

.bottom-bar-item-icon img {
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid whitesmoke;
}

.bottom-bar-item-icon {
    transition: transform 0.3s ease;
    /* Smooth transition effect */
}

.bottom-bar-item-icon:hover {
    transform: scale(1.1);
    /* Scales up the image */
}

[data-theme='dark'] .toggleThemeButton {
    color : whitesmoke;
} 

[data-theme='light'] .toggleThemeButton {
    color : #2f2f2f;
} 

.toggle-theme-bottom-bar {
    color: whitesmoke;
}